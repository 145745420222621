import React from "react"
import SectionTitle from "../../components/SectionTitle"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import PageWrapper from "../../components/layout/PageWrapper"
import ServicesDesign from "../../components/content/ServicesDesign"

const GasFireplaceInstallers = () => (
  <Layout>
    <SEO
      title="Bespoke fireplace design and installation"
      description="Designing fireplaces and complete chimney systems"
    />
    <PageWrapper>
      <SectionTitle
        title="Bespoke fireplace design"
        strapline="Designing fireplaces and complete chimney systems"
      />
      <ServicesDesign />
    </PageWrapper>
  </Layout>
)

export default GasFireplaceInstallers
