import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"
import ServicesImages from "./ServicesImages"

const query = graphql`
  {
    images: allFile(
      filter: { relativeDirectory: { eq: "pages/services/services-design" } }
    ) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(quality: 64, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const ServicesDesign = () => {
  const data = useStaticQuery(query)

  return (
    <Wrapper className="section-center">
      <article className="text-container">
        <p>
          Prior the installation we can advice the best possible solution to
          your fireplace, stove or gas burner. We can design your fireplace from
          scratch (together with entire chimney system) or just simply supply a
          drawing showing the fireplace options.
        </p>
        <p>
          We can also provide bespoke, custom-made fire grates for all different
          fireplace styles (from antique to contemporary) and all necessary
          fireplace accessories.
        </p>
        <div className="back-to-button">
          <Link to={"/services"} className="btn btn--small">
            Back to services
          </Link>
        </div>
      </article>
      <aside className="image-container">
        <ServicesImages data={data} />
      </aside>
    </Wrapper>
  )
}

export default ServicesDesign

const Wrapper = styled.main`
  .image-container {
    margin-bottom: 2rem;
    height: 100%;
  }
  .text-container p {
    line-height: 2;
  }
  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 4rem;

    .text-container {
      grid-column: 1 / span 6;
    }

    .image-container {
      grid-column: 7 / -1;
    }
  }
`
